import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home"; // Current App content moved to Home.js
import Blog from "./pages/Blog"; // New Blog page
import BlogPost from "./pages/BlogPost"; // Blog post page for individual articles

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home Page */}
        <Route path="/blog" element={<Blog />} /> {/* Blog Listing Page */}
        <Route path="/blog/:slug" element={<BlogPost />} /> {/* Individual Blog Post */}
      </Routes>
    </Router>
  );
};

export default App;