import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BlogClient } from "seobot";
import "./style/Blog.css";

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(0); // Current page index
  const [loading, setLoading] = useState(false);

  const SEOBOT_API_KEY = process.env.REACT_APP_SEOBOT_API_KEY;
  const client = new BlogClient(SEOBOT_API_KEY);

  const loadArticles = async (pageIndex) => {
    try {
      setLoading(true);
      const response = await client.getArticles(pageIndex, 10); // Fetch 10 articles per page
      console.log(response);
      const articlesArray = response.articles || []; // Extract articles array or default to an empty array
      console.log(articlesArray);
      setArticles((prev) => [...prev, ...articlesArray]); // Append new articles
      setLoading(false);
    } catch (err) {
      console.error("Error fetching articles:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadArticles(page);
  }, [page]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <div className="blog-page">
      <h1 className="blog-page-title">Blog Articles</h1>
      <div className="blog-list">
        {articles.map((article) => (
          <div className="blog-item" key={article.id}>
            <img
              src={article.image}
              alt={article.headline}
              className="blog-item-image"
            />
            <div className="blog-item-content">
              <Link to={`/blog/${article.slug}`} className="blog-item-title">
                {article.headline}
              </Link>
              <p className="blog-item-meta">{article.metaDescription}</p>
              <p className="blog-item-date">
                Last updated: {formatDate(article.updatedAt)}
              </p>
              <Link to={`/blog/${article.slug}`} className="read-now-button">
                Read Now
              </Link>
            </div>
          </div>
        ))}
      </div>
      {loading && <p>Loading...</p>}
      {!loading && articles.length >= 10 && (
        <button className="load-more-button" onClick={loadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

export default Blog;
