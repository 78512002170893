import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { BlogClient } from "seobot";
import "./style/BlogPost.css";
import { Helmet } from "react-helmet"; // Import Helmet for SEO

const BlogPost = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const SEOBOT_API_KEY = process.env.REACT_APP_SEOBOT_API_KEY;
  const client = new BlogClient(SEOBOT_API_KEY);

  useEffect(() => {
    const fetchArticleAndRelated = async () => {
      try {
        const currentArticle = await client.getArticle(slug);
        setArticle(currentArticle);
        console.log(currentArticle);

        let related = [];
        // Fetch articles by category
        if (currentArticle.category?.slug) {
          const categoryArticles = await client.getCategoryArticles(
            currentArticle.category.slug,
            0,
            10
          );
          related = Array.isArray(categoryArticles) ? categoryArticles : [];
        }

        // If no related articles found by category, check tags
        if (related.length === 0 && currentArticle.tags?.length > 0) {
          for (let tag of currentArticle.tags) {
            const tagArticles = await client.getTagArticles(tag.slug, 0, 10);
            if (Array.isArray(tagArticles)) {
              related = tagArticles;
              if (related.length > 0) break; // Stop if related articles are found
            }
          }
        }

        // If no related articles found by category or tags, fetch random articles
        if (related.length === 0) {
          const randomArticles = await client.getArticles(0, 10);
          related = Array.isArray(randomArticles) ? randomArticles : [];
        }

        // Exclude the current article from the list
        related = related.filter((item) => item.slug !== slug).slice(0, 2);

        setRelatedArticles(related);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching article or related articles:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchArticleAndRelated();
  }, [slug]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!article) return <div>Article not found</div>;

  return (
    <>
      <Helmet>
        <title>{article.headline}</title>
        <meta name="description" content={article.metaDescription} />
        <meta
          name="keywords"
          content={article.metaKeywords}
        />
        <meta name="author" content="Jeel Patel" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={article.headline} />
        <meta property="og:description" content={article.metaDescription} />
        <meta property="og:image" content={article.image} />
        <meta
          property="og:url"
          content={`https://howtoretain.com/blog/${article.slug}`}
        />
        <meta property="og:type" content="article" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.headline} />
        <meta name="twitter:description" content={article.metaDescription} />
        <meta name="twitter:image" content={article.image} />
      </Helmet>

      <main>
        <div className="blog-post-container">
          <nav className="breadcrumb">
            <Link to="/">Home</Link> &gt; <Link to="/blog">Posts</Link> &gt;{" "}
            {article.headline}
          </nav>
          <div className="blog-post-header">
            <img
              src={article.image}
              alt={article.headline}
              className="blog-post-image"
            />
            <div className="blog-post-meta">
              <span>
                Category: {article.category?.title || "Uncategorized"}
              </span>
              <span>Reading Time: {article.readingTime} min</span>
            </div>
            <div className="blog-post-tags">
              {article.tags?.map((tag) => (
                <span key={tag.id} className="blog-post-tag">
                  {tag.title}
                </span>
              ))}
            </div>
          </div>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: article.html }}
          />

          {/* Related Articles Section */}
          {/* Related Articles Section */}
          {relatedArticles.length > 0 && (
            <div className="related-articles">
              <h2>Keep reading</h2>
              <div className="related-articles-list">
                {relatedArticles.map((related) => (
                  <div key={related.id} className="related-article-item">
                    <img
                      src={related.image}
                      alt={related.headline}
                      className="related-article-image"
                    />
                    <div className="related-article-details">
                      <h3>
                        <Link to={`/blog/${related.slug}`}>
                          {related.headline}
                        </Link>
                      </h3>
                      <p>{related.metaDescription}</p>
                    </div>
                  </div>
                ))}
              </div>
              {relatedArticles.length > 2 && (
                <Link to="/blog" className="view-more">
                  View more &gt;
                </Link>
              )}
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default BlogPost;
