import React from "react";
import { Link } from "react-scroll"; // For smooth scrolling
import { FaArrowDown } from "react-icons/fa"; // Downward arrow icon
import "./style/Home.css";
import { Helmet } from "react-helmet";

const Homepage = () => {
  return (
    <>
    <Helmet>
      <title>Fail-Proof Retention Strategy | Increase Customer Retention</title>
      <meta
        name="description"
        content="Discover fail-proof retention strategies to increase customer lifetime value and ROI. Tailored strategies for business builders in competitive markets."
      />
      <meta
        name="keywords"
        content="customer retention, marketing strategy, retention marketing, increase ROI, business builders, how to retain, webengage expert, clevertap expert, moengage expert"
      />
      <meta name="author" content="Jeel Patel" />

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="Fail-Proof Retention Strategy | Increase Customer Retention"
      />
      <meta
        property="og:description"
        content="Discover fail-proof retention strategies to increase customer lifetime value and ROI. Tailored strategies for business builders in competitive markets."
      />
      <meta property="og:image" content="https://howtoretain.com/public/before-8d9_At.jpg" />
      <meta property="og:url" content="https://howtoretain.com" />
      <meta property="og:type" content="website" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Fail-Proof Retention Strategy | Increase Customer Retention"
      />
      <meta
        name="twitter:description"
        content="Discover fail-proof retention strategies to increase customer lifetime value and ROI. Tailored strategies for business builders in competitive markets."
      />
      <meta name="twitter:image" content="/before-8d9_At.jpg" />

      {/* Website Schema */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "How to Retain",
          "url": "https://howtoretain.com",
          "description":
            "Discover fail-proof retention marketing strategies to maximize your revenue and customer lifetime value.",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://howtoretain.com/?q={search_term}",
            "query-input": "required name=search_term",
          },
        })}
      </script>
    </Helmet>
    <main>
    <div className="app">
      {/* Hero Section */}
      <header className="hero-section">
        <div className="container">
          <h2 className="attention">🔴 Attention! Business Builders</h2>
          <h1 className="main-title">
            FAIL-PROOF STRATEGY IS WAITING TO <br /> DELIGHT YOUR CUSTOMERS
          </h1>
          <p className="subheading">
            Increase Customer Retention Even You Are In Highly Competitive
            Market.
          </p>
          <a href="https://tidycal.com/jeelpatel/45-minutes-meeting" className="cta-button" id="btn-atf">
            CLAIM YOUR FREE STRATEGY SESSION 📅
          </a>
          <p className="scarcity">
            Be quick! I can only manage ONE more brand for this month.
          </p>

          {/* Downward Arrow */}
          <Link
            to="conversion-section"
            smooth={true}
            duration={500}
            className="down-arrow"
          >
            <FaArrowDown />
          </Link>
        </div>
      </header>

      {/* Conversion Section */}
      <section id="conversion-section" className="conversion-section">
        <div className="container">
          <h2 className="section-title">Hello Business Builder!</h2>
          <p>
            If you want to know the most effective marketing strategy that helps
            your business to increase customer lifetime value & overall ROI...
          </p>
          <p>
            <strong>Without spending extra money on paid ads...</strong>
          </p>
          <p>Then this will be the most important message you'll ever read.</p>
          <h3>Here's Why...</h3>
          <ul>
            <li>
              Did you know{" "}
              <strong>
                iOS 14+ updates crushed the social media ads industry
              </strong>{" "}
              like a bug?
            </li>
            <li>
              Facebook (Meta) or third-party apps can no longer access users'
              activity on iPhones, making interest-based targeting far less
              profitable.
            </li>
            <li>
              <strong>Acquiring new customers & retargeting ads</strong> are
              becoming more costly nowadays. 🤯
            </li>
            <li>
              Top of that, meta ads are too much on IG or FB, which lower the
              customer consideration rate for your brand...
            </li>
            <li>
              That’s why{" "}
              <strong>CAC (Customer Acquisition Cost) is skyrocketing.</strong>
            </li>
          </ul>
          <p>
            <strong>And it’s going to get worse.</strong>
          </p>

          <h3>But I Have a Solution!</h3>
          <p>
            You need <strong>EXTRA MONEY</strong> to cover the extra cost for
            ads. This page holds the key to earning an{" "}
            <strong>extra 20% revenue</strong> so you can spend more on ads.
          </p>
          <p>
            So, turn off all your distractions and read every single word on
            this page.
          </p>

          <h3>Got It? Let's Get Started!</h3>
          <p>
            Firstly, spending money on traffic is good, but looking for cheaper
            traffic is not.
          </p>
          <p>
            Big brands who have money will spend more on ads and cut you down.
            That’s why you need to focus on
            <strong> RETENTION MARKETING</strong>.
          </p>
          <p>
            After working with many D2C brands, I found a{" "}
            <strong>FAIL-PROOF retention marketing</strong> strategy that helps
            you to generate <strong>extra 20% revenue</strong> every month.
          </p>
          <p>
            Not just that but your customer will come back & buy again from you
            so customer lifetime value will increase...
          </p>
          <p>So Do ROI...</p>

          <h3>The Results Speak for Themselves:</h3>
          <ul>
            <li>
              💰 Took a Haircare brand from{" "}
              <strong>3% to 30% revenue in 3 months</strong> (10x ROI over 6
              months).
            </li>
            <li>
              💰 Helped an Indian skincare brand go from{" "}
              <strong>₹0 to ₹1,00,000 revenue in 4 months</strong>
              (at least 30% revenue every month).
            </li>
          </ul>
          <p>Please note, I have only used three simple strategies yet...</p>

          <div className="image-container">
            <img
              src="https://cdn.cmsfly.com/62c06bc25a7999006c096fc6/before-8d9_At.png"
              alt="Before and After Results"
              className="results-image"
            />
          </div>

          <p>
            Look, To build any business online, you need to focus on these three
            things: <strong>TRAFFIC, CONVERSION & RETENTION.</strong>
          </p>
          <p>
            And you know, for quality traffic, you need to spend more on ads &
            SEO...
          </p>
          <p>
            To earn more money you need to convert your existing visitors into
            buyers and existing customers into loyal customers who buy your
            products again & again…
          </p>
          <p>
            In other words, the{" "}
            <strong>"FAIL-PROOF Retention Marketing Strategy"</strong> is for
            converting existing traffic into loyal customers...
          </p>
          <p>Sounds cool?</p>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works-section">
        <div className="container">
          <h2 className="section-title">
            Our 3-Step Process to Maximize Your Revenue
          </h2>
          <div className="steps">
            <div className="step">
              <div className="icon-container">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/189/189665.png"
                  alt="Analyze"
                />
              </div>
              <h3>Analyze Your Current Retention Performance</h3>
              <p>
                Understand your current retention performance and identify areas
                for improvement.
              </p>
            </div>
            <div className="step">
              <div className="icon-container">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2910/2910762.png"
                  alt="Strategy"
                />
              </div>
              <h3>Create a Custom Retention Strategy</h3>
              <p>
                Build a tailored retention strategy that aligns with your
                business goals.
              </p>
            </div>
            <div className="step">
              <div className="icon-container">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                  alt="Implement"
                />
              </div>
              <h3>Implement and Scale for Consistent Growth</h3>
              <p>
                Execute the strategy and scale retention efforts to achieve
                long-term growth.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Optimized Last CTA Section */}
      <section className="cta-section">
        <div className="container">
          <a href="https://tidycal.com/jeelpatel/45-minutes-meeting" className="cta-button" id="btn-btf">
            CLAIM YOUR FREE STRATEGY SESSION 📅
          </a>
          <p className="scarcity">
            Be quick! I can only manage ONE more brand for this month.
          </p>
        </div>
      </section>
    </div>
    </main>
    </>
  );
};

export default Homepage;